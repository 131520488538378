// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Home, DocumentCode2, OceanProtocol, Level, ShieldCross, InfoCircle, I24Support, Driving } from 'iconsax-react';

// icons
const icons = {
  welcomePage: Home,
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: 'Main',
  title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    {
      id: 'welcome-page',
      title: <FormattedMessage id="welcome-page" />,
      type: 'item',
      url: '/welcome-page',
      icon: icons.welcomePage
    },
    {
      id: 'esg',
      title: <FormattedMessage id="esg" />,
      type: 'item',
      url: '/esg-page',
      icon: icons.menuLevel
    },

    {
      id: 'cerved',
      title: <FormattedMessage id="cerved" />,
      type: 'item',
      url: 'https://wwww.cervedpropertyservices.com',
      icon: icons.chipMenu,
      external: true,
      target: true
    }
  ]
};

export default support;
